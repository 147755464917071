import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { db } from '@/services/db/index';
import { membersDb } from '@/services/db/members';
import { Group } from '@/models/group';
import moment from 'moment/moment';
import { ListState } from '@/models/listState';
import { AuthResponse } from '@/models/authResponse';
import { EventBus } from '@/services/event-bus';
import { lessonPlansDb } from '@/services/db/lessonPlans';
import { makeRequest } from '@/services/api-request';
import { LessonPlan, LessonPlanGroup } from '@/models/lessonPlan';
import { lessonPlanGroupsDb } from '@/services/db/lessonPlanGroups';

/**
 * List state values for the tags related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const state = {
  isLoadingGroups: false,
  isLoadingMembers: false,
  isLoadingLessonPlans: false,
  isLoadingLessonPlanGroups: false,
};

type StoreDbState = typeof state;

const debug = (value: string) => {
  if (process.env.NODE_ENV === 'development') {
    console.log(value);
  }
};

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  updateIsLoadingGroups: (moduleState: StoreDbState, loading: boolean) => {
    moduleState.isLoadingGroups = loading;
  },
  updateIsLoadingMembers: (moduleState: StoreDbState, loading: boolean) => {
    moduleState.isLoadingMembers = loading;
  },
  updateIsLoadingLessonPlans: (moduleState: StoreDbState, isLoadingLessonPlans: boolean) => {
    moduleState.isLoadingLessonPlans = isLoadingLessonPlans;
  },
  updateIsLoadingLessonPlanGroups: (moduleState: StoreDbState, isLoadingLessonPlanGroups: boolean) => {
    moduleState.isLoadingLessonPlanGroups = isLoadingLessonPlanGroups;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  loadGroupsIntoStoreDb: async (
    context: ActionContext<StoreDbState, RootState>,
    options = { forceSync: false, allGroups: false }
  ): Promise<Array<Group>> => {
    const t0 = performance.now();
    let groups: Array<Group> = [];
    // only run one at a time
    if (context.state.isLoadingGroups) {
      debug('[loadGroupsIntoStoreDb] already running so return');
      return groups;
    }
    // if you are not logged in do not know what data to give you
    const isLoggedIn: boolean = context.rootGetters['auth/isLoggedIn']();
    if (!isLoggedIn) {
      // redirect to login page
      EventBus.$emit('invalid-token');
      return groups;
    }
    context.commit('updateIsLoadingGroups', true);
    debug('[loadGroupsIntoStoreDb] START');

    try {
      groups = await context.dispatch('fetchAllGroupsFromApi', options.allGroups);
      await membersDb.loadGroups(groups);
    } catch (e) {
      console.error('Error fetching groups from API', e);
    }

    const t1 = performance.now();
    const seconds = (((t1 - t0) % 60000) / 1000).toFixed(1);
    context.commit('updateIsLoadingGroups', false);
    await db.setSyncDate(moment().format('YYYY-MM-DD'), 'member');
    debug('[loadGroupsIntoStoreDb] FINISHED in ' + seconds + ' seconds');
    return groups;
  },

  processLoadingMembers: async (context: ActionContext<RootState, RootState>, group: Group): Promise<boolean> => {
    try {
      const members = await context.dispatch('groupMembers/fetchMembersFromApiByGroupId', group, { root: true });
      // save the members into db store
      await membersDb.loadGroupMembersByGroupId(group.id, members);
      return true;
    } catch (error) {
      console.error('Error fetching members from API', error);
      return false;
    }
  },

  startMemberSync: async (
    context: ActionContext<RootState, RootState>,
    options = { forceSync: false, allGroups: false, allGroupsImported: false, userNgoId: '' }
  ): Promise<boolean> => {
    const isOnline = await membersDb.checkIsOnline();
    if (!isOnline) {
      return false;
    }
    context.commit('updateIsLoadingMembers', true);
    // If we have already imported all groups and we are just sync a single user delete all their groups
    if (options.allGroupsImported && options.forceSync && !options.allGroups && options.userNgoId) {
      const [d1, d2] = await membersDb.deleteGroups(options.userNgoId);
      console.log(`Deleted ${d1} groupMembers,${d2} groups for ${options.userNgoId}`);
      return true;
    }
    return db.isReadyToLoad('member', options.forceSync);
  },

  endMemberSync: async (context: ActionContext<RootState, RootState>): Promise<number> => {
    context.commit('updateIsLoadingMembers', false);
    const res = membersDb.setSyncDate(moment().format('YYYY-MM-DD'), 'member');
    EventBus.$emit('member-sync-end');
    return res;
  },

  /**
   * Fetches the members from the API using the current module and root state.
   *
   * @param {ActionContext<ListState, RootState>} context
   */
  fetchAllGroupsFromApi: async (
    context: ActionContext<ListState, RootState>,
    allGroups: false
  ): Promise<Array<Group>> => {
    const sessionDate = context.rootGetters['groups/sessionDate'];
    let url = `/groups?active=1&with_levels=1&with_remote=1&date=${sessionDate}`;
    if (allGroups) {
      url += '&all_groups=1';
    }
    const response: AuthResponse = await makeRequest('GET', url);
    return response.body as Array<Group>;
  },

  loadLessonPlansIntoDb: async (context: ActionContext<StoreDbState, RootState>, force: boolean): Promise<void> => {
    if (context.state.isLoadingLessonPlans) {
      // Only run one at a time
      debug('[loadLessonsPlans] running');
      return Promise.resolve();
    }
    // If you are not logged in do not know what data to give you
    const isLoggedIn: boolean = context.rootGetters['auth/isLoggedIn']();
    if (!isLoggedIn) {
      EventBus.$emit('invalid-token');
      debug('[loadLessonsPlans] not logged in');
      return Promise.resolve();
    }
    debug('[loadLessonsPlans] START');
    context.commit('updateIsLoadingLessonPlans', true);
    try {
      if (!force) {
        const ready = await lessonPlansDb.isReadyToLoad('lessonPlan');
        if (!ready) {
          debug('[loadLessonsPlans] not ready / sync not needed');
          context.commit('updateIsLoadingLessonPlans', false);
          return Promise.resolve();
        }
      }
      let response: AuthResponse | null = null;
      response = await makeRequest('GET', '/lesson-plans?include_detail=1');
      const lessonPlans = response.body as Array<LessonPlan>;
      await Promise.all(lessonPlans.map(plan => context.dispatch('saveLessonPlan', plan)));

      const showLessonPlanGroups =
        context.rootGetters['auth/isFeatureEnabled']('ENABLE_SESSION_PLANNING') &&
        context.rootGetters['auth/isFeatureEnabled']('CAN_EDIT_SESSION_PLAN');
      if (showLessonPlanGroups) {
        response = await makeRequest('GET', '/lesson-plan-groups');
        const lessonPlanGroups = response.body as Array<LessonPlanGroup>;
        await Promise.all(lessonPlanGroups.map(group => context.dispatch('saveLessonPlanGroup', group)));
      }

      db.setSyncDate(moment().format('YYYY-MM-DD'), 'lessonPlan');
      debug('[loadLessonsPlans] finished');
    } catch (error) {
      context.commit('updateIsLoadingLessonPlans', false);
      console.error(error);
    }
    context.commit('updateIsLoadingLessonPlans', false);
  },

  // Fetch a single lesson plan and save to the db
  fetchAndSaveLessonPlan: async (context: ActionContext<StoreDbState, RootState>, uuid: string): Promise<void> => {
    const plan = await context.dispatch('lessonPlans/fetchLessonPlanFromApi', uuid, { root: true });
    debug('fetched and saving plan ' + uuid);
    await lessonPlansDb.savePlan(plan);
  },

  // Update a single lesson plan and save to the db
  saveLessonPlan: async (context: ActionContext<StoreDbState, RootState>, plan: LessonPlan): Promise<void> => {
    debug('saving plan ' + plan.uuid);
    await lessonPlansDb.savePlan(plan);
  },

  // Delete a lesson plan and its details from the db
  deleteLessonPlan: async (context: ActionContext<StoreDbState, RootState>, id: string): Promise<void> => {
    debug('deleting plan ' + id);
    await lessonPlansDb.deletePlan(id);
  },

  // Lesson Plan Groups
  // Update a single lesson plan group and save to the db
  saveLessonPlanGroup: async (
    context: ActionContext<StoreDbState, RootState>,
    lessonPlanGroup: LessonPlanGroup
  ): Promise<void> => {
    debug('saving lesson plan group ' + lessonPlanGroup.uuid);
    await lessonPlanGroupsDb.saveLessonPlanGroup(lessonPlanGroup);
  },

  // Delete a lesson plan and its details from the db
  deleteLessonPlanGroup: async (context: ActionContext<StoreDbState, RootState>, id: string): Promise<void> => {
    debug('deleting lesson plan group ' + id);
    await lessonPlanGroupsDb.deleteLessonPlanGroup(id);
  },

  getSetting: async (context: ActionContext<StoreDbState, RootState>, setting: string): Promise<any> => {
    return db.getSetting(setting);
  },

  setSetting: async (
    context: ActionContext<StoreDbState, RootState>,
    { setting, value }: { setting: string; value: any }
  ): Promise<string> => {
    return db.setSetting(setting, value);
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  isLoadingGroups: (moduleState: StoreDbState) => moduleState.isLoadingGroups,
  isLoadingMembers: (moduleState: StoreDbState) => moduleState.isLoadingMembers,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
