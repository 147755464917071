import ConfirmDangerousModal from '@/components/common/ConfirmDangerousModal.vue';

const ConfirmDangerous = {
  install(Vue: any, options = {} as any) {
    const vuetify = options.vuetify;
    Vue.prototype.$confirmDangerous = (
      title: string,
      closeLabel: string,
      confirmLabel: string,
      warning: string,
      instructions: string,
      expected: string
    ) => {
      const ModalClass = Vue.extend(ConfirmDangerousModal);
      const container = document.querySelector('[data-app=true]') || document.body;

      // Same approach as vuetify-confirm
      return new Promise(resolve => {
        const dialog = new ModalClass(
          Object.assign(
            {},
            {
              vuetify,
              propsData: { title, closeLabel, confirmLabel, warning, instructions, expected },
              destroyed: () => {
                container.removeChild(dialog.$el);
                resolve(dialog.value);
              },
            }
          )
        );
        container.appendChild(dialog.$mount().$el);
      });
    };
  },
};

export default ConfirmDangerous;
