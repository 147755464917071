import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';
import { LessonPlan, Section } from '@/models/lessonPlan';
import { lessonPlansDb } from '@/services/db/lessonPlans';
import { AuthUser } from '@/models/authUser';
import _uniq from 'lodash/uniq';

const getDefaultState = () => ({
  selectedPlan: {} as LessonPlan,
  coaches: {} as AuthUser,
});

const state = getDefaultState();

type PlanState = typeof state;

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: PlanState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateSelected: (moduleState: PlanState, data: LessonPlan) => {
    moduleState.selectedPlan = data;
  },
  updateCoaches: (moduleState: PlanState, data: AuthUser) => {
    moduleState.coaches = data;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<PlanState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  /**
   * Fetch selected lesson plan
   *
   * @param {ActionContext} context
   * @return void
   */
  fetchLessonPlanFromApi: async (context: ActionContext<PlanState, RootState>, uuid: string) => {
    const url = `/lesson-plans/${uuid}`;
    try {
      const response: AuthResponse = await makeRequest('GET', url);
      context.commit('updateSelected', response.body);
      return response.body;
    } catch (error) {
      console.error('Error fetching lesson plan ', error);
      context.commit('updateSelected');
    }
  },

  fetchLessonPlanFromDb: async (context: ActionContext<PlanState, RootState>, uuid: string) => {
    try {
      const plan = await lessonPlansDb.getLessonPlan(uuid);
      if (plan) {
        context.commit('updateSelected', plan);
      }
    } catch (error) {
      console.error('Error fetching lesson plan from db ', error);
      context.commit('updateSelected');
    }
  },

  /**
   * Update the session setup (header) section of the lesson plan
   *
   * @param {ActionContext} context
   * @param {LessonPlan} data
   * @return void
   */
  updateSessionSetup: async (context: ActionContext<PlanState, RootState>, data: LessonPlan) => {
    const url = `/lesson-plans/${data.uuid}/field-values`;
    await makeRequest('PATCH', url, { body: JSON.stringify({ headers: data.headers }) });
    return await context.dispatch('storeDb/fetchAndSaveLessonPlan', data.uuid, { root: true });
  },

  /**
   * Create a new section for the lesson plan
   *
   * @param {ActionContext} context
   * @param {string} title
   * @return void
   */
  createSection: async (
    context: ActionContext<PlanState, RootState>,
    { planUuid, title }: { planUuid: string; title: string }
  ) => {
    const url = `/lesson-plans/${planUuid}/sections`;
    try {
      return await makeRequest('POST', url, { body: JSON.stringify({ title }) });
    } catch (error) {
      console.error('Error creating section ', error);
    }
  },

  /**
   * Update the content section for the lesson plan
   *
   * @param {ActionContext} context
   * @param {Section} section
   * @return void
   */
  updateSection: async (context: ActionContext<PlanState, RootState>, section: Section) => {
    const planUuid = context.getters.selectedPlan.uuid;
    const url = `/lesson-plans/${planUuid}/sections/${section.id}`;
    const data = {
      title: section.title,
      description: section.description,
      tag: section.tag,
    };
    try {
      return await makeRequest('PATCH', url, { body: JSON.stringify(data) });
    } catch (error) {
      console.error('Error updating session ', error);
    }
  },

  /**
   * Update the skills for the content section
   *
   * @param {ActionContext} context
   * @param {Section} section
   * @return void
   */
  updateSectionSkills: async (context: ActionContext<PlanState, RootState>, section: Section) => {
    const planUuid = context.getters.selectedPlan.uuid;
    const url = `/lesson-plans/${planUuid}/sections/${section.id}/skills`;
    try {
      return await makeRequest('PATCH', url, { body: JSON.stringify(section.skills) });
    } catch (error) {
      console.error('Error updating session skills ', error);
    }
  },

  updatePlanTitle: async (context: ActionContext<PlanState, RootState>, title: string) => {
    const planUuid = context.getters.selectedPlan.uuid;
    const url = `/lesson-plans/${planUuid}`;
    return await makeRequest('PATCH', url, { body: JSON.stringify({ title }) });
  },

  duplicatePlan: async (context: ActionContext<PlanState, RootState>, plan: LessonPlan) => {
    const url = `/lesson-plans/${plan.uuid}/copy`;
    const title = await lessonPlansDb.getNewLessonPlanTitle(plan.title);
    return await makeRequest('POST', url, { body: JSON.stringify({ title }) });
  },

  deletePlan: async (context: ActionContext<PlanState, RootState>, uuid: string) => {
    const url = `/lesson-plans/${uuid}`;
    return await makeRequest('DELETE', url);
  },

  getCoaches: async (context: ActionContext<PlanState, RootState>) => {
    const url = '/users?status[]=active&type[]=coach';
    try {
      const response: AuthResponse = await makeRequest('GET', url);
      context.commit('updateCoaches', response.body);
      return response.body;
    } catch (error) {
      console.error('Error fetching coaches ', error);
      context.commit('updateCoaches');
    }
  },

  updateShares: async (context: ActionContext<PlanState, RootState>, shares: Array<string>) => {
    const planUuid = context.getters.selectedPlan.uuid;
    const url = `/lesson-plans/${planUuid}/shared-users`;
    return await makeRequest('POST', url, { body: JSON.stringify({ shares }) });
  },

  duplicateSection: async (context: ActionContext<PlanState, RootState>, section: Section) => {
    const planUuid = context.getters.selectedPlan.uuid;
    const url = `/lesson-plans/${planUuid}/sections/${section.id}/copy`;
    try {
      return await makeRequest('POST', url);
    } catch (error) {
      console.error('Error duplicating section ', error);
    }
  },

  deleteSection: async (context: ActionContext<PlanState, RootState>, section: Section) => {
    const planUuid = context.getters.selectedPlan.uuid;
    const url = `/lesson-plans/${planUuid}/sections/${section.id}`;
    try {
      return await makeRequest('DELETE', url);
    } catch (error) {
      console.error('Error deleting section ', error);
    }
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  selectedPlan: (moduleState: PlanState) => moduleState.selectedPlan,
  coaches: (moduleState: PlanState) => moduleState.coaches,
  sessionSkillIds: (moduleState: PlanState) => {
    return _uniq(moduleState?.selectedPlan?.sections?.flatMap((s: Section) => s.skills || []));
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
