import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';
import { LessonPlan, LessonPlanGroup } from '@/models/lessonPlan';
import { lessonPlanGroupsDb } from '@/services/db/lessonPlanGroups';

const getDefaultState = () => ({
  selected: {} as LessonPlanGroup,
});

const state = getDefaultState();

type LessonPlanGroupState = typeof state;

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: LessonPlanGroupState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateSelected: (moduleState: LessonPlanGroupState, data: LessonPlanGroup) => {
    moduleState.selected = data;
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<LessonPlanGroupState, RootState>): Promise<void> => {
    context.commit('resetState');
  },

  fetchFromApi: async (context: ActionContext<LessonPlanGroupState, RootState>, uuid: string) => {
    const url = `/lesson-plan-groups/${uuid}`;
    try {
      const response: AuthResponse = await makeRequest('GET', url);
      context.commit('updateSelected', response.body);
      return response.body;
    } catch (error) {
      console.error('Error fetching lesson plan group', error);
      context.commit('updateSelected');
    }
  },

  fetchFromDb: async (context: ActionContext<LessonPlanGroupState, RootState>, uuid: string) => {
    try {
      const group = await lessonPlanGroupsDb.getLessonPlanGroup(uuid);
      if (group) {
        context.commit('updateSelected', group);
      }
    } catch (error) {
      console.error('Error fetching lesson plan group from db ', error);
      context.commit('updateSelected');
    }
  },

  updateAndReload: async (
    context: ActionContext<LessonPlanGroupState, RootState>,
    { uuid, changes }: { uuid: string; changes: any }
  ) => {
    const newGroup = Object.assign({}, context.getters.selected, changes);
    await context.dispatch('storeDb/saveLessonPlanGroup', newGroup, { root: true });
    await context.dispatch('fetchFromDb', uuid);
  },

  updateTitle: async (context: ActionContext<LessonPlanGroupState, RootState>, title: string) => {
    const uuid = context.getters.selected.uuid;
    const url = `/lesson-plan-groups/${uuid}`;
    const r = await makeRequest('PATCH', url, { body: JSON.stringify({ title }) });
    await context.dispatch('updateAndReload', { uuid, changes: { title } });
    return r;
  },

  updatePlans: async (
    context: ActionContext<LessonPlanGroupState, RootState>,
    { uuid, plans }: { uuid: string; plans: Array<LessonPlan> }
  ) => {
    const url = `/lesson-plan-groups/${uuid}/plans`;
    const r = await makeRequest('PATCH', url, { body: JSON.stringify({ plans: plans.map(plan => plan.uuid) }) });
    await context.dispatch('updateAndReload', { uuid, changes: { plans } });
    return r;
  },

  delete: async (context: ActionContext<LessonPlanGroupState, RootState>, uuid: string) => {
    const url = `/lesson-plan-groups/${uuid}`;
    return await makeRequest('DELETE', url);
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  selected: (moduleState: LessonPlanGroupState) => moduleState.selected,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
