import { ErrorIdentity } from '@/models/errorResponseBody';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';
import { LessonPlan } from '@/models/lessonPlan';
import { lessonPlansDb } from '@/services/db/lessonPlans';
import { getActions, getGetters, getMutations } from '../list-state-mixin';

/**
 * List state values for the LessonPlans related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = (): ListState => ({
  itemsPerPage: 5,
  items: [] as Array<LessonPlan>,
  currentPage: 1,
  total: 0,
  defaultSort: 'aname',
  sort: 'aname', // TODO use sortDirection separately when this is implemented
  isLoading: true,
  selected: [], // Selected LessonPlans.
  search: '',
  errors: [] as Array<ErrorIdentity>,
  schemeSlug: '',
  tab: 0,
});

const state = getDefaultState();

const actions = getActions();
const mutations = getMutations();
const getters = getGetters();

mutations.resetState = (moduleState: ListState) => {
  Object.assign(moduleState, getDefaultState());
};

actions.fetchItems = async (context: ActionContext<ListState, RootState>, force = false) => {
  try {
    await context.dispatch('storeDb/loadLessonPlansIntoDb', force, { root: true });
    const items = await lessonPlansDb.getPlans(
      context.state.itemsPerPage,
      context.state.schemeSlug,
      context.state.search
    );
    context.commit('updateItems', items);
    context.commit('updateTotal', items.length);
    context.commit('updateLoading', false);
  } catch (error) {
    console.log(error);
    context.dispatch('resetToEmpty');
  }
};

actions.createItem = async (
  context: ActionContext<ListState, RootState>,
  { title, slug }: { title: string; slug: string }
): Promise<false | string> => {
  const resp: AuthResponse = await makeRequest('POST', '/lesson-plans', {
    body: JSON.stringify({ title, scheme_uuid: slug }),
  });
  const locHeader = resp.response.headers.get('Location');
  if (locHeader) {
    const newUuid = locHeader.split('/').pop();
    await context.dispatch('storeDb/fetchAndSaveLessonPlan', newUuid, { root: true });
    context.commit('updateSearch', '');
    context.commit('updateItemsPerPage', 100);
    const items = await lessonPlansDb.getPlans(
      context.state.itemsPerPage,
      context.state.schemeSlug,
      context.state.search
    );
    context.commit('updateItems', items);
    context.commit('updateTotal', items.length);
    context.commit('updateLoading', false);

    return newUuid || false;
  }
  return false;
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
