
import { Component, Vue, Watch } from 'vue-property-decorator';
import { Route } from 'vue-router';
import { memberRoute, planningRoute, schemeRoute } from '@/router/coach';
import { Breadcrumb } from '@/models/breadcrumb';
import { EventBus } from '@/services/event-bus';
import SessionDateNavigation from '@/components/coach/members/SessionDateNavigation.vue';

@Component({
  name: 'BreadcrumbsComponent',
  components: {
    SessionDateNavigation,
  },
})
export default class BreadcrumbsComponent extends Vue {
  created() {
    EventBus.$on('user-initialized', () => {
      this.onUrlChange(this.$route);
    });
  }

  @Watch('$route', { immediate: true, deep: true })
  /**
   * Called when the route changes, responsible updating breadcrumbs and the last visited route.
   *
   * @return {void}
   */
  onUrlChange(newRoute: Route): void {
    if (this.isMemberRoute) {
      this.$store.commit('nav/updateMemberTabLastVisitedRoute', newRoute);
      this.$store.dispatch('nav/updateMemberBreadcrumbs', newRoute);
    } else if (this.isPlanningRoute) {
      const routeParams = newRoute.params as {
        schemeSlug: string;
        lessonPlanUuid: string;
        lessonPlanGroupUuid: string;
      };
      const schemeSlug = typeof routeParams.schemeSlug !== 'undefined' ? routeParams.schemeSlug : null;
      const lessonPlanUuid = typeof routeParams.lessonPlanUuid !== 'undefined' ? routeParams.lessonPlanUuid : '';
      const lessonPlanGroupUuid =
        typeof routeParams.lessonPlanGroupUuid !== 'undefined' ? routeParams.lessonPlanGroupUuid : '';
      this.$store.commit('nav/updatePlanningTabLastVisitedRoute', newRoute.path);
      this.$store.dispatch('nav/updatePlanningBreadcrumbs', { schemeSlug, lessonPlanUuid, lessonPlanGroupUuid });
    } else if (this.isSchemeRoute) {
      const routeParams = newRoute.params as { schemeSlug: string; schemeNodeId: string };
      const schemeSlug = typeof routeParams.schemeSlug !== 'undefined' ? routeParams.schemeSlug : null;
      const schemeNodeId = typeof routeParams.schemeNodeId !== 'undefined' ? '/' + routeParams.schemeNodeId : null;
      this.$store.commit('nav/updateSchemeTabLastVisitedRoute', newRoute.path);
      this.$store.dispatch('nav/updateSchemeBreadcrumbs', { schemeSlug, schemeNodeId });
    }
  }

  /**
   * Returns the string that defines the member route, this is a const and can be used for testing the tab the
   * route relates to.
   *
   * @return {string}
   */
  get memberRoute(): string {
    return memberRoute;
  }

  /**
   * Returns the string that defines the scheme route, this is a const and can be used for testing the tab the
   * route relates to.
   *
   * @return {string}
   */
  get schemeRoute(): string {
    return schemeRoute;
  }

  /**
   * Returns the string that defines the planning route, this is a const and can be used for testing the tab the
   * route relates to.
   *
   * @return {string}
   */
  get planningRoute(): string {
    return planningRoute;
  }

  /**
   * Returns the string that defines the member route, this is a const and can be used for testing the tab the
   * route relates to.
   *
   * @return {string}
   */
  get isMemberRoute(): boolean {
    return this.$route.path.startsWith(memberRoute);
  }

  get isPlanningRoute(): boolean {
    return this.$route.path.startsWith(planningRoute);
  }

  /**
   * Tests if the route is related to the scheme tab.
   *
   * @return {boolean}
   */
  get isSchemeRoute(): boolean {
    return this.$route.path.startsWith(schemeRoute);
  }

  /**
   * Computed getter function for the nav breadcrumbs
   *
   * @return {Array<Breadcrumb>>}
   */
  get breadcrumbItems(): Array<Breadcrumb> {
    if (this.isMemberRoute) {
      return this.$store.getters['nav/memberBreadcrumbs'];
    } else if (this.isSchemeRoute) {
      return this.$store.getters['nav/schemeBreadcrumbs'];
    } else if (this.isPlanningRoute) {
      return this.$store.getters['nav/planningBreadcrumbs'];
    } else {
      return [];
    }
  }

  get groupId() {
    return typeof this.$route.params.groupId !== 'undefined' ? parseInt(this.$route.params.groupId, 10) : 0;
  }

  get canAssessInPast() {
    return this.$store.getters['auth/isFeatureEnabled']('CAN_ASSESS_IN_THE_PAST');
  }

  get canChangeDate() {
    return this.isMemberRoute && this.groupId > 0 && this.canAssessInPast;
  }
}
