
import { Component, Vue, Prop } from 'vue-property-decorator';

@Component
export default class ConfirmDangerousModal extends Vue {
  public value = false;
  private confirmationText = '';

  @Prop() private title!: string;
  @Prop() private closeLabel!: string;
  @Prop() private confirmLabel!: string;
  @Prop() private warning!: string;
  @Prop() private instructions!: string;
  @Prop() private expected!: string;

  choose(value: boolean) {
    this.value = value;
    this.$destroy();
  }
}
