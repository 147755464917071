import { StoreDb } from '@/services/db/index';
import { LessonPlan } from '@/models/lessonPlan';

class LessonPlansDB extends StoreDb {
  async savePlan(plan: LessonPlan): Promise<void> {
    return await this.transaction('rw', this.lessonPlans, () => {
      this.lessonPlans.put(plan, plan.uuid);
    });
  }

  async getPlans(
    limit: number,
    schemeSlug: string | undefined,
    search: string | undefined
  ): Promise<Array<LessonPlan>> {
    const collection = this.lessonPlans.limit(limit);
    if (schemeSlug) {
      collection.filter((plan: LessonPlan) => plan.scheme_slug === schemeSlug);
    }
    if (search) {
      const regexp = new RegExp(search, 'i');
      collection.filter((plan: LessonPlan) => regexp.test(plan.title));
    }
    return await collection.sortBy('title');
  }

  async getLessonPlan(uuid: string): Promise<LessonPlan | undefined> {
    return await this.transaction('r', this.lessonPlans, async () => {
      return await this.lessonPlans
        .where('uuid')
        .equals(uuid)
        .first();
    });
  }

  async getNewLessonPlanTitle(title: string): Promise<string> {
    let num = 2;
    let exists;
    let newTitle = `${title.substr(0, 48)}-${num}`;
    do {
      newTitle = `${title.substr(0, 48)}-${num}`;
      exists = await this.lessonPlans
        .where('title')
        .equals(newTitle.toString())
        .first();
      if (exists) {
        num++;
      }
    } while (exists);

    return newTitle;
  }

  async deletePlan(uuid: string): Promise<void> {
    await this.transaction('rw', this.lessonPlans, () => {
      return this.lessonPlans
        .where('uuid')
        .equals(uuid)
        .delete();
    });
  }
}

export const lessonPlansDb = new LessonPlansDB();
