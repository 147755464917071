import { DataTableOptions } from '@/models/dataTableOptions';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { Label } from '@/models/label';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { AuthResponse } from '@/models/authResponse';
import { Labels } from '@/models/labels';
import { makeRequest } from '@/services/api-request';

/**
 * List state values for the tags related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = () => ({
  itemsPerPage: 10,
  items: [] as Array<Label>,
  currentPage: 1,
  total: 0,
  defaultSort: 'name',
  sort: 'name',
  sortDirection: 'a',
  isLoading: true,
  selected: [], // Selected labels.
  search: '',
  errors: [] as Array<ErrorIdentity>,
});

const state = getDefaultState();

/**
 * Vuex store mutations available for updating state values above.
 * These should be the only way state values are updated.
 * Should be synchronous transactions only to ensure predictability of state.
 *
 * @property mutations - Object
 */
const mutations = {
  resetState: (moduleState: ListState) => {
    Object.assign(moduleState, getDefaultState());
  },
  updateItems: (moduleState: ListState, data: Array<Label>) => {
    moduleState.items = data || [];
  },

  updateItemsPerPage: (moduleState: ListState, noOfItems: number) => {
    moduleState.itemsPerPage = noOfItems;
  },

  updateTotal: (moduleState: ListState, total: number) => {
    moduleState.total = total;
  },

  updateCurrentPage: (moduleState: ListState, currentPage: number) => {
    moduleState.currentPage = currentPage;
  },

  updateSortOptions: (moduleState: ListState, dataTableOptions: DataTableOptions) => {
    const { sortBy, sortDesc } = dataTableOptions;

    // so api needs either a or d directly in front of the sortfield
    // eg sort=dfirstname,astatus
    if (sortBy.toString().length > 1) {
      moduleState.sort = sortBy.toString();
      // if sortDesc is false then sort ascending
      if (sortDesc.toString() === 'true') {
        moduleState.sortDirection = 'd';
      } else {
        moduleState.sortDirection = 'a';
      }
    } else {
      moduleState.sort = moduleState.defaultSort;
      moduleState.sortDirection = 'a';
    }
  },

  /**
   * Updates the current sort
   *
   * @param {ListState} moduleState
   * @param {string} sortName
   */
  updateSort: (moduleState: ListState, sortName: string): void => {
    moduleState.sort = sortName;
  },

  /**
   * Update sortDirection - a or d
   *
   * @param {ListState} moduleState
   * @param {string} sortDirection
   */
  updateSortDirection: (moduleState: ListState, sortDirection: string): void => {
    moduleState.sortDirection = sortDirection;
  },

  updateLoading: (moduleState: ListState, isLoading: boolean) => {
    moduleState.isLoading = isLoading;
  },

  updateSearch: (moduleState: ListState, searchStr: string) => {
    moduleState.search = searchStr;
  },

  // updateErrors: (moduleState: ListState, errorDetail: ErrorIdentity) => {
  //     moduleState.errors!.push(errorDetail);
  // },

  clearErrors: (moduleState: ListState) => {
    moduleState.errors = [];
  },
};

/**
 * Available functions for handling business logic relating to this store modules state properties.
 * Can be asynchronous.
 * Interacts with the modules state properties via committing one or more synchronous mutations.
 *
 * @property actions - Object
 */
const actions = {
  resetState: async (context: ActionContext<ListState, RootState>): Promise<void> => {
    context.commit('resetState');
  },
  /**
   * Fetches tags with current page, sort and sort order included.
   *
   * @param {ActionContext} context
   * @return void
   */
  fetchLabels: async (context: ActionContext<ListState, RootState>) => {
    const queryParams: Record<string, string> = {
      // per_page: `${context.state.itemsPerPage}`,
      // page: `${context.state.currentPage}`,
      sort: context.state.sort,
    };
    if (context.state.search && context.state.search.length > 0) {
      queryParams.search = context.state.search;
    }
    const url = `/labels?${new URLSearchParams(queryParams)}`;

    try {
      const authResponse: AuthResponse = await makeRequest('GET', url);
      context.commit('updateItems', authResponse.body as Array<Label>);
      context.commit('updateTotal', context.state.items.length);
      context.commit('updateLoading', false);
    } catch (error) {
      context.dispatch('resetToEmpty');
      console.error(error);
    }
  },

  resetToEmpty: (context: ActionContext<ListState, RootState>) => {
    context.commit('updateItems', []);
    context.commit('updateTotal', 0);
    context.commit('updateLoading', false);
  },

  /**
   * Updates the modules data table options on change of table state.
   *
   * @param {ActionContext} context
   * @param {DataTableOptions} dataTableOptions
   * @return void
   */
  updateOptions: (context: ActionContext<ListState, RootState>, dataTableOptions: DataTableOptions) => {
    context.commit('updateCurrentPage', dataTableOptions.page);
    context.commit('updateSortOptions', dataTableOptions);
    context.dispatch('fetchLabels');
  },

  /**
   * Updates the modules current page to reflect pagination choice by user.
   *
   * @param {ActionContext} context
   * @param {number} page
   * @return void
   */
  updateLabelPage: (context: ActionContext<ListState, RootState>, page: number) => {
    context.commit('updateCurrentPage', page);
    context.dispatch('fetchLabels');
  },
};

/**
 * Available functions for code external to the store to retrieved this modules state properties values.
 * Can alter, calculate with or filter these values before return.
 *
 * @property getters - Object
 */
const getters = {
  itemsPerPage: (moduleState: ListState) => moduleState.itemsPerPage,
  labels: (moduleState: ListState) => moduleState.items,
  planFooterLabels: (moduleState: { items: Labels }) => (moduleState.items ? moduleState.items.PLAN_FOOTER : null),
  planHeaderLabels: (moduleState: { items: Labels }) => (moduleState.items ? moduleState.items.PLAN_HEADER : null),
  currentPage: (moduleState: ListState) => moduleState.currentPage,
  total: (moduleState: ListState) => {
    return parseInt(String(moduleState.total), 10);
  },
  isLoading: (moduleState: ListState) => moduleState.isLoading,
  search: (moduleState: ListState) => moduleState.search,
  errors: (moduleState: ListState) => moduleState.errors,
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
