import _sortBy from 'lodash/sortBy';
import { ErrorIdentity } from '@/models/errorResponseBody';
import { ListState } from '@/models/listState';
import { ActionContext } from 'vuex';
import { RootState } from '@/models/rootState';
import { makeRequest } from '@/services/api-request';
import { AuthResponse } from '@/models/authResponse';
import { LessonPlanGroup } from '@/models/lessonPlan';
import { lessonPlanGroupsDb } from '@/services/db/lessonPlanGroups';
import { getActions, getGetters, getMutations } from '../list-state-mixin';

/**
 * List state values for the LessonPlans related store properties.
 * Should only be updated via mutations defined below.
 *
 * @property state - ListState
 */
const getDefaultState = (): ListState => ({
  itemsPerPage: 5,
  items: [] as Array<LessonPlanGroup>,
  currentPage: 1,
  total: 0,
  defaultSort: 'aname',
  sort: 'aname', // TODO use sortDirection separately when this is implemented
  isLoading: true,
  selected: [], // Selected LessonPlans.
  search: '',
  errors: [] as Array<ErrorIdentity>,
  schemeSlug: '',
});

const state = getDefaultState();

const actions = getActions();
const mutations = getMutations();
const getters = getGetters();

mutations.resetState = (moduleState: ListState) => {
  Object.assign(moduleState, getDefaultState());
};

actions.fetchItems = async (context: ActionContext<ListState, RootState>, force = false) => {
  try {
    await context.dispatch('storeDb/loadLessonPlansIntoDb', force, { root: true });
    let items = await lessonPlanGroupsDb.getLessonPlanGroups(
      context.state.itemsPerPage,
      context.state.schemeSlug,
      context.state.search
    );
    items = _sortBy(items, 'title');
    context.commit('updateItems', items);
    context.commit('updateTotal', items.length);
    context.commit('updateLoading', false);
  } catch (error) {
    console.log(error);
    context.dispatch('resetToEmpty');
  }
};

actions.createItem = async (
  context: ActionContext<ListState, RootState>,
  { title, schemeSlug }: { title: string; schemeSlug: string }
) => {
  const resp: AuthResponse = await makeRequest('POST', '/lesson-plan-groups', {
    body: JSON.stringify({ title, scheme_uuid: schemeSlug }),
  });
  await context.dispatch('storeDb/saveLessonPlanGroup', resp.body, { root: true });
  context.commit('updateSearch', '');
  context.commit('updateItemsPerPage', 100);
  const items = await lessonPlanGroupsDb.getLessonPlanGroups(
    context.state.itemsPerPage,
    context.state.schemeSlug,
    context.state.search
  );
  context.commit('updateItems', items);
  context.commit('updateTotal', items.length);
  context.commit('updateLoading', false);
};

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true,
};
