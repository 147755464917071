
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import { GroupScope } from '@/components/mixins/groupscope';
import { Online } from '@/components/mixins/online';
import { getReadableDate } from '@/services/utils';
import moment from 'moment';

@Component
export default class SessionDateNavigation extends mixins(GroupScope, Online) {
  get currentDate() {
    return getReadableDate(this.groupDate);
  }

  get loading(): boolean {
    return this.$store.getters['groups/isLoading'];
  }

  async goTo(direction: 'next' | 'prev') {
    if (this.group.nav && direction in this.group.nav) {
      const nav = this.group.nav[direction];
      if (nav) {
        // Everything expects the groups and members to be fully loaded to DB + vuex, so we navigate the entire day here
        const date = moment(nav.start).format('YYYY-MM-DD');
        const route = {
          name: this.$route.name || 'groups-menu',
          params: { groupId: nav.group_id },
        };

        // Load groups + members from the API and load new groups into vuex
        await this.$store.dispatch('groups/changeDate', { date });

        // also reload the group members into vuex
        await this.$store.dispatch('groupMembers/fetchMembersByGroupId', nav.group_id);
        this.$router.push(route);
      }
    }
  }

  get previousDate() {
    if (this.group.nav && 'prev' in this.group.nav && this.group.nav.prev) {
      return this.group.nav.prev.start;
    }
  }

  get nextDate() {
    if (this.group.nav && 'next' in this.group.nav && this.group.nav.next) {
      return this.group.nav.next.start;
    }
  }
}
