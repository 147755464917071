import { StoreDb } from '@/services/db/index';
import { LessonPlanGroup } from '@/models/lessonPlan';

class LessonPlanGroupsDB extends StoreDb {
  async saveLessonPlanGroup(lessonPlanGroup: LessonPlanGroup): Promise<void> {
    return await this.transaction('rw', this.lessonPlanGroups, () => {
      this.lessonPlanGroups.put(lessonPlanGroup, lessonPlanGroup.uuid);
    });
  }

  async getLessonPlanGroups(
    limit: number,
    schemeSlug: string | undefined,
    search: string | undefined
  ): Promise<Array<LessonPlanGroup>> {
    if (search) {
      const regexp = new RegExp(search, 'i');
      return await this.lessonPlanGroups
        .filter((g: LessonPlanGroup) => g.scheme_slug === schemeSlug && regexp.test(g.title))
        .limit(limit)
        .sortBy('title');
    }
    return await this.lessonPlanGroups
      .filter((g: LessonPlanGroup) => g.scheme_slug === schemeSlug)
      .limit(limit)
      .sortBy('title');
  }

  async getLessonPlanGroup(uuid: string): Promise<LessonPlanGroup | undefined> {
    return await this.transaction('r', this.lessonPlanGroups, async () => {
      return await this.lessonPlanGroups
        .where('uuid')
        .equals(uuid)
        .first();
    });
  }

  async getNewLessonPlanGroupTitle(title: string): Promise<string> {
    let num = 2;
    let exists;
    let newTitle = `${title.substr(0, 48)}-${num}`;
    do {
      newTitle = `${title.substr(0, 48)}-${num}`;
      exists = await this.lessonPlanGroups
        .where('title')
        .equals(newTitle.toString())
        .first();
      if (exists) {
        num++;
      }
    } while (exists);

    return newTitle;
  }

  async deleteLessonPlanGroup(uuid: string): Promise<void> {
    await this.transaction('rw', this.lessonPlanGroups, () => {
      return this.lessonPlanGroups
        .where('uuid')
        .equals(uuid)
        .delete();
    });
  }
}

export const lessonPlanGroupsDb = new LessonPlanGroupsDB();
